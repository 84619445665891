import { mapHelper } from "@/utils/common";
const wishTypeOptions = [
  {
    value: 1,
    label: "愿望",
  },
  {
    value: 2,
    label: "祝福",
  },
  {
    value: 3,
    label: "其他",
  },
];
const auditStatusOptions = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];
const { map: auditStatusMap, setOps: auditStatusOps } =
  mapHelper.setMap(auditStatusOptions);
const showStatusOptions = [
  {
    value: 0,
    label: "隐藏",
  },
  {
    value: 1,
    label: "展示",
  },
];
export {
  wishTypeOptions,
  auditStatusOptions,
  showStatusOptions,
  auditStatusMap,
  auditStatusOps,
};
